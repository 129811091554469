import axios from "axios";
const { baseURL } = require("@/assets/js/config");
const $http = axios.create({
  baseURL,
});

const state = {
  coupons: [],
  current_coupon: null,
};

const getters = {
  fetchCoupons(state) {
    return state.coupons;
  },
  fetchCurrentCoupon(state) {
    return state.current_coupon;
  },
};

const mutations = {
  SET_COUPONS: (state, coupons) => {
    state.coupons = coupons;
  },
  ADD_COUPON: (state, coupon) => {
    state.coupons = [...state.coupons, coupon];
  },
  SET_COUPON: (state, coupon) => {
    state.current_coupon = coupon;
  },
  REMOVE_COUPON: (state, id) => {
    state.coupons = state.coupons.filter((coupon) => coupon.id != id);
  },
};

const actions = {
  loadCoupons({ commit }, { token }) {
    return $http
      .post(
        "/coupon.php",
        { form_type: "get_coupons" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        commit("SET_COUPONS", res.data["data"]);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  loadCouponDetails({ commit }, { token, id }) {
    return $http
      .post(
        "/coupon.php",
        { form_type: "get_coupon_detail", id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        commit("SET_COUPON", res.data["data"]);
        return Promise.resolve(res.data["data"]);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  createCoupon({ commit }, { token, detail }) {
    return $http
      .post("/coupon.php", detail, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        commit("ADD_COUPON", res.data["data"]);
      });
  },

  emptyCurrentCoupon({ commit }) {
    commit("SET_COUPON", null);
  },

  updateCoupon({ commit }, { token, detail }) {
    return $http
      .post("/coupon.php", detail, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        commit("SET_COUPON", res.data["data"]);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  deleteCoupon({ commit }, { token, detail }) {
    return $http
      .post("/coupon.php", detail, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        commit("REMOVE_COUPON", res.data["data"]);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
