<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="8" class="pa-0 d-none d-sm-flex">
        <v-img style="height: 100vh" src="@/assets/background.jpg"></v-img>
      </v-col>
      <v-col cols="12" sm="4" class="pa-0 text-center">
        <v-img
          class="mx-auto"
          contain
          max-height="150"
          max-width="250"
          src="@/assets/logo.png"
        ></v-img>
        <password-change v-if="isPasswordChange" @passwordChanged="passwordChangeSuccess"></password-change>
        <sign-in
          @submit="loggedIn"
          :isLoading="isLoginLoading"
          v-else
        ></sign-in>
        <p
          class="text-center mt-3 body-2"
          style="cursor: pointer"
          v-if="!isPasswordChange"
        >
          Forgot Password?
          <span
            class="text ml-1"
            @click="changePassword()"
            style="color: #6e2fed; text-decoration: underline"
            >Get a new one</span
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import signIn from "@/components/Login/SignIn";
import PasswordChange from "@/components/Login/Password_change";
export default {
  data() {
    return {
      isLoginLoading: false,
      isPasswordChange: false,
    };
  },
  components: {
    signIn,
    PasswordChange,
  },
  methods: {
    ...mapActions(["loginUser"]),
    changePassword() {
      this.isPasswordChange = true;
    },
    passwordChangeSuccess() {
      this.isPasswordChange = false;
    },

    loggedIn(user) {
      this.isLoginLoading = true;
      this.loginUser(user)
        .then((_) => {
          this.$router.push({ name: "Home" });
          this.$toasted.show("Logged in Successfully", {
            type: "success",
            duration: 3000,
            position: "top-center",
            theme: "toasted-primary",
            icon: "mdi-account",
            iconPack: "mdi",
          });
        })
        .catch((err) => {
          this.$toasted.show(err, {
            type: "error",
            duration: 3000,
            position: "top-center",
            theme: "toasted-primary",
            icon: "mdi-account-alert",
            iconPack: "mdi",
          });
        })
        .finally(() => {
          this.isLoginLoading = false;
        });
    },
  },
};
</script>
