import axios from 'axios'
const { baseURL } = require('@/assets/js/config')
const $http = axios.create({
    baseURL
})

const state = {
    allGrades: [],
    allSubjects: [],
    allDetails: null,
    latestSubscriptionDetails: null,
}

const getters = {
    allDetails(state) {
        return state.allDetails
    },
    allGrades(state) {
        return state.allGrades
    },
    allSubjects(state) {
        return state.allSubjects
    },
    latestSubscriptionDetails(state) {
        return state.latestSubscriptionDetails
    },

}

const mutations = {
    SET_GRADES: (state, _grades) => {
        state.allGrades = _grades
    },
    SET_SUBJECTS: (state, _subjects) => {
        state.allSubjects = _subjects
    },
    SET_DETAILS: (state, _details) => {
        state.allDetails = _details
    },
    SET_SUBSCRIPTION_DETAILS: (state, _details) => {
        state.latestSubscriptionDetails = _details
    },

}

const actions = {
    fetchSubsDetail({ commit }, token) {
        return $http.post('/subsdetail.php', { 'form_type': 'subsdetail' }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            commit('SET_DETAILS', res.data['data'])
        }).catch(err => {
            return Promise.reject(err)
        })
    },

    updateSubsDetail({ commit }, { token, detail }) {
        return $http.post('/subsdetail.php', detail, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            return Promise.resolve(res)
        }).catch(err => {
            return Promise.reject(err)
        })
    },

    cancelSubscription({commit}, {token, data}){
        return $http.post('/subscription.php', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            return Promise.resolve(res)
        }).catch(err => {
            return Promise.reject(err)
        })
    },

    fetchAllSubjects({ commit }) {
        return $http.post('/subsdetail.php', { 'form_type': 'get_subjects' },).then(res => {
            commit('SET_SUBJECTS', res.data)
        }).catch(err => {
            return Promise.reject(err)
        })
    },

    fetchAllGrades({ commit }) {
        return $http.post('/subsdetail.php', { 'form_type': 'get_grades' },).then(res => {
            commit('SET_GRADES', res.data)
        }).catch(err => {
            return Promise.reject(err)
        })
    },

    fetchLatestSubscription({ commit }, token) {
        return $http.post('/subscription.php', { 'form_type': 'get_subscription' }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            commit('SET_SUBSCRIPTION_DETAILS', res.data['data'])
        }).catch(err => {
            return Promise.reject(err)
        })
    },
    

}


export default {
    state,
    getters,
    mutations,
    actions
}
