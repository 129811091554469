import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Loading spinner
import { LoadingPlugin } from 'vuetify-loading-overlay'
Vue.use(LoadingPlugin, {
  // props
  spinnerProps: {
    color: "#fd9910"
  },
  overlayProps: {
  }
})

// Toasted Plugin
import Toasted from 'vue-toasted'
Vue.use(Toasted)

// Masonry Plugin
import { VueMasonryPlugin } from 'vue-masonry'
Vue.use(VueMasonryPlugin)

// Dayjs
import dayjs from 'dayjs'
import $common from '@/assets/js/common'
Vue.prototype.dayjs = dayjs
Vue.prototype.$common = $common

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
