import axios from 'axios'
const { baseURL } = require('@/assets/js/config')
const $http = axios.create({
  baseURL
})

const state = {
  isLoggedIn: true,
  userToken: localStorage.getItem('a2stoken') ? localStorage.getItem('a2stoken') : '',
  userName: localStorage.getItem('name') ? localStorage.getItem('name') : '',
  type: localStorage.getItem('type') ? localStorage.getItem('type') : '',
  otp_id: null,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.userToken = token
    localStorage.setItem('a2stoken', token)
  },
  SET_NAME: (state, name) => {
    state.userName = name
    localStorage.setItem('name', name)
  },
  SET_TYPE: (state, type) => {
    state.type = type
    localStorage.setItem('type', type)
  },
  DELETE_TOKEN: (state) => {
    state.userToken = ''
    localStorage.clear();
  },
  FORGOT_PASSWORD_SET_OTP_ID: (state, id) => {
    state.otp_id = id
  },

}

const actions = {
  loginUser({ commit }, payload) {
    return $http.post('/auth.php', payload).then(res => {
      console.log(res.data)
      commit('SET_TOKEN', res.data['token'])
      commit('SET_NAME', res.data['name'])
      commit('SET_TYPE', res.data['type'])
    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
  },
  fetchAllUsers({ commit }, token) {
    return $http.post('/user.php', { 'form_type': 'all_users' }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then(res => {
      return Promise.resolve(res.data)

    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
  },


  forgotPasswordSendOtp({ commit }, { email }) {
    return $http.post('/forgotpassword.php', { 'form_type': 'generate_otp', 'email': email }).then(res => {
      return Promise.resolve(res.data)
    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })


  },
  forgotPasswordValidateOtp({ commit }, { email, otp }) {
    return $http.post('/forgotpassword.php', { 'form_type': 'verify_otp', 'email': email, 'otp': otp }).then(res => {
      console.log(res.data)
      commit('FORGOT_PASSWORD_SET_OTP_ID', res.data.otp_id)
      return Promise.resolve(res.data)
    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })

    // return $http.post('/user/forgot/validate', { email, otp }).then(res => {
    //   commit('FORGOT_PASSWORD_SET_OTP_VALIDATION', res.data)
    // })
  },
  forgotPasswordUpdate({ commit }, { password_again, otp_id, email }) {
    return $http.post('/forgotpassword.php', { 'form_type': 'update_new_password', 'email': email, 'otp_id': otp_id, 'password': password_again, }).then(res => {
      console.log(res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
  },

  logoutUser({ commit }) {
    commit('DELETE_TOKEN')
  },
  userStatusToggle({ commit }, { token, user_id, status }) {
    return $http.post('/user.php', { 'form_type': 'update_user', 'userid': user_id, 'status': status }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }).then(res => {
      return Promise.resolve(res.data)

    }).catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
  },
}
const getters = {
  userToken(state) {
    return state.userToken
  },
  userName(state) {
    return state.userName
  },
  type(state) {
    return state.type
  },
  getOtpId(state) {
    return state.otp_id
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
