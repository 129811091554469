import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import subsDetail from "./modules/subsDetail";
import transaction from "./modules/transaction";
import coupon from "./modules/coupon";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    subsDetail,
    transaction,
    user,
    coupon,
  },
});
