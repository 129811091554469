<template>
  <div>
    <div v-if="!new_pass" class="mx-8">
      <h2 class="mt-4 mb-4 grey--text">
        {{ new_pass ? "New Password" : "Verify Email" }}
      </h2>
      <v-alert
        v-if="!new_pass"
        class="py-1 body-2"
        dismissible
        type="info"
        border="left"
        elevation="1"
        colored-border
        >Don't forget to check your <span style="color: #EF4444">spam</span> folder
      </v-alert>
      <p class="text-center body-2"></p>
      <v-text-field
        class="pt-4"
        label="Enter Email ID"
        v-model="email"
        dense
        outlined
        :disabled="otp"
      ></v-text-field>
      <v-text-field
        v-if="otp"
        label="Enter Otp"
        v-model="otp_data"
        dense
        outlined
      ></v-text-field>
      <v-btn
        v-if="otp"
        block
        rounded
        color="warning"
        dark
        @click="validateOtp()"
        >Verify OTP</v-btn
      >
      <v-btn
        v-else
        block
        rounded
        color="#6e2fed"
        dark
        depressed
        @click="sendOtp()"
        >Send OTP</v-btn
      >
    </div>
    <div class="pa-4 mx-4" v-else>
      <h1 class="text-center font-weight-light mb-8">Change Password</h1>
      <v-text-field
        dense
        label="New Password"
        v-model="password"
        outlined
        hide-details
        class="mb-4"
      ></v-text-field>
      <v-text-field
        dense
        label="Confirm Password"
        v-model="password_again"
        outlined
      ></v-text-field>
      <v-btn
        block
        rounded
        color="#6e2fed"
        depressed
        @click="changePassword()"
        dark
        >Confirm</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      otp: false,
      new_pass: false,
      email: "",
      otp_data: "",
      password: "",
      password_again: "",
    };
  },
  methods: {
    ...mapActions([
      "forgotPasswordSendOtp",
      "forgotPasswordValidateOtp",
      "forgotPasswordUpdate",
    ]),
    switch() {
      this.otp ? (this.otp = !this.otp) : (this.new_pass = !this.new_pass);
    },
    sendOtp() {
      this.$vloading.show();
      this.forgotPasswordSendOtp({ email: this.email })
        .then((res) => {
          this.otp = !this.otp;
          this.$toasted.show("Please enter the otp sent on your email", {
            type: "info",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
          });
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.message, {
            type: "danger",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
          });
        })
        .finally((_) => {
          this.$vloading.hide();
        });
    },
    validateOtp() {
      this.$vloading.show();
      this.forgotPasswordValidateOtp({
        email: this.email,
        otp: this.otp_data,
      })
        .then((res) => {
          this.new_pass = !this.new_pass;
        })
        .catch((err) => {
          this.$toasted.show(err.response.data, {
            type: "error",
            duration: 3000,
            position: "top-center",
            theme: "bubble",
          });
        })
        .finally((_) => {
          this.$vloading.hide();
        });
    },
    changePassword() {
      if (this.password === this.password_again) {
        this.$vloading.show();
        this.forgotPasswordUpdate({
          password_again: this.password_again,
          otp_id: this.getOtpId,
          email: this.email,
        })
          .then((res) => {
            this.$emit('passwordChanged', false);
            this.$toasted.show("Your password has been updated successfully", {
              type: "success",
              duration: 3000,
              position: "top-center",
              theme: "bubble",
            });
          })
          .catch((err) => {
            this.$toasted.show(err, {
              type: "error",
              duration: 3000,
              position: "top-center",
              theme: "bubble",
            });
          })
        .finally((_) => {
          this.$vloading.hide();
        });
      } else {
        this.$toasted.show("Passwords are different", {
          type: "error",
          duration: 3000,
          position: "top-center",
          theme: "bubble",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getOtpId"]),
  },
};
</script>

<style>
</style>
