// This is a component by which we emit the username and password field for SignIN
<template>
  <div class="mx-8">
    <h2 class="mt-4 mb-4 grey--text">Sign In to Acces2Success</h2>
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- Email text field  -->
      <v-text-field
        :rules="emailRules"
        v-model="user.email"
        label="User Email"
        prepend-icon="mdi-account"
        required
      ></v-text-field>
      <!-- Password field  -->
      <v-text-field
        required
        :rules="[(v) => !!v || 'Password is required']"
        v-model="user.password"
        prepend-icon="mdi-lock"
        :type="hidePassword ? 'password' : 'text'"
        :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
        label="Password"
        @click:append="hidePassword = !hidePassword"
      />
    </v-form>
    <v-btn class="mt-2" :loading="isLoading" block depressed rounded color="#7761aa" dark @click="loggedIn()"
      >Log In</v-btn
    >
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      valid: true,
      user: {
        email: "",
        password: "",
        form_type: "auth",
      },
      hidePassword: true,
      emailRules: [
        (v) => !!v || "User Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loggedIn() {
      if (this.$refs.form.validate())
        // Emitting data to main Login Page
        this.$emit("submit", this.user);
    },
  },
  computed: {
    ...mapGetters([""]),
  },
};
</script>
