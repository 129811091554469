import axios from 'axios'
const { baseURL } = require('@/assets/js/config')
const $http = axios.create({
    baseURL
})

const state = {

}

const mutations = {

}

const actions = {
    createTransaction({ commit }, { token, card_detail }) {
        return $http.post('/transaction.php', card_detail, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            return Promise.resolve(res.data['data'])
        }).catch(err => {
            console.log(err)
            return Promise.reject(err)
        })
    },

    fetchTransaction({ commit }, { token, id }) {
        return $http.post('/subscription.php', { 'form_type': 'get_subscription_transactions', 'user_id': id }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            return Promise.resolve(res.data)

        }).catch(err => {
            console.log(err)
            return Promise.reject(err)
        })
    },

    updateCard({ commit }, { token, data }) {
        return $http.post('/subsdetail.php', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {
            return Promise.resolve(res.data)

        }).catch(err => {
            console.log(err)
            return Promise.reject(err)
        })
    },

}
const getters = {

}

export default {
    state,
    getters,
    mutations,
    actions
}
